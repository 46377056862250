import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/common/store'
import apiMixin from './api/mixin'
import vuetify from './plugins/vuetify'
import Dialogs from 'v-dialogs'
import request from './axios'
import { DialogToast } from 'v-dialogs'
import {getStorage,setStorage} from "@/common/utils.js"
import '@mdi/font/css/materialdesignicons.css'
import Element from 'element-ui'
import 'element-plus/dist/index.css'



Vue.prototype.request = request;
Vue.prototype.$api = apiMixin
Vue.prototype.$store = store




//配置弹窗
Vue.use(Dialogs, {
  // global config options...
  
})
Vue.use(Element, {
 
  zIndex: 1000
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

// 添加全局错误处理函数
// router.onError(error => {
//   if (error.name === 'NavigationDuplicated') {
//     console.log('当前路由已经在跳转中...');
//   } else {
//     throw error;
//   }
// });
