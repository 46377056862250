<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
.text-hidden{
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		
		.text-hidden-2,.text-hidden-3,.text-hidden-4{
			display: -webkit-box;
			-webkit-box-orient: vertical;
			overflow: hidden;
			word-break:break-all; 
		}
		.text-hidden-2{
			-webkit-line-clamp: 2;
		}
		.text-hidden-3{
			-webkit-line-clamp: 3; 
		}
		.text-hidden-4{
			-webkit-line-clamp: 4; 
		}
		
		.flex-shrink{
			flex-shrink:0;
		}
		.flex,.flex-ac,.flex-jc,.flex-col,.flex-jc-sb,.flex-jc-se,.flex-jc-ac,.flex-jc-sa,.flex-end,.flex-warp {
			display: flex;
		}
		.flex-jc-se {
		  justify-content: space-evenly;
		}
		.border-box{
			box-sizing: border-box;
		}
		.pr {
		  position: relative;
		}
		.pa {
		  position: absolute;
		}

		.flex1 {
		  flex: 1;
		}
		
		
		.flex-ac {
		  align-items: center !important;
		}
		.flex-jc {
		  justify-content: center;
		}
		.flex-col {
		  flex-direction: column;
		}
		.flex-jc-sb {
		  justify-content: space-between;
		}
		
		.flex-jc-ac {
		  justify-content: center;
		  align-items: center;
		}
		.flex-jc-sa {
		  justify-content: space-around;
		}
		.flex-end {
		  align-items: flex-end;
		  justify-content: flex-end;
		}
		.flex-warp {
		  flex-wrap: wrap;
		}
		
		.fw{
		  font-weight: bold;
		}
		.fws{
		  font-weight: 520;
		  color:#000;
		}
		.fwred{
		  font-weight: bold;
		  color:#B20C0D;
		}
		.tr { 
		  text-align: right;
		}
		.tl {
		  text-align: left;
		}
		.tc {
		  text-align: center;
		}
		
		.fs10{font-size: 10px}
		.fs12{font-size: 12px}
		.fs14{font-size: 14px}
		.fs16{font-size: 16px}
		.fs18{font-size: 20px}
		.fs20{font-size: 22px}
		.fs22{font-size: 24px}
		.fs24{font-size: 26px}
		.fs26{font-size: 28px}
		.fs28{font-size: 30px}
		.fs30{font-size: 32px}
		.fs32{font-size: 32px}
		.fs34{font-size: 34px}
		.fs36{font-size: 36px}
		.fs38{font-size: 38px}
		
		.mb16{margin-bottom: 16px}
		.mb18{margin-bottom: 18px}
		.mb20{margin-bottom: 20px}
		.mb24{margin-bottom: 24px}
		.mb26{margin-bottom: 26px}
		.mb28{margin-bottom: 28px}
		.mb30{margin-bottom: 30px}
		.mb32{margin-bottom: 32px}
		.mb34{margin-bottom: 34px}
		.mb36{margin-bottom: 36px}
		.mb38{margin-bottom: 38px}
		
		.mt6{margin-top: 6px}
		.mt10{margin-top: 10px}
		.mt16{margin-top: 16px}
		.mt20{margin-top: 20px}
		.mt24{margin-top: 24px}
		.mt26{margin-top: 26px}
		.mt28{margin-top: 28px}
		.mt30{margin-top: 30px}
		.mt32{margin-top: 32px}
		.mt34{margin-top: 34px}
		.mt36{margin-top: 36px}
		.mt38{margin-top: 38px}
		
		
		.gborder{
			color: #a4a7c4;
			border:1px solid #E6E6E6;
		}
		
		.m-pd{
			margin-left:28px ;
			margin-right:28px ;
		}
		.page-pd{
			padding-left:28px ;
			padding-right:28px ;
		}
		.margin-0{margin:0}
		.margin-xs{margin:10px}
		.margin-sm{margin:20px}
		.margin{margin:30px}
		.margin-lg{margin:40px}
		.margin-xl{margin:50px}
		.margin-top-xs{margin-top:10px}
		.margin-top-sm{margin-top:20px}
		.margin-top{margin-top:30px}
		.margin-top-lg{margin-top:40px}
		.margin-top-xl{margin-top:50px}
		.margin-right-xs{margin-right:10px}
		.margin-right-sm{margin-right:20px}
		.margin-right{margin-right:30px}
		.margin-right-lg{margin-right:40px}
		.margin-right-xl{margin-right:50px}
		.margin-bottom-xs{margin-bottom:10px}
		.margin-bottom-sm{margin-bottom:20px}
		.margin-bottom{margin-bottom:30px}
		.margin-bottom-lg{margin-bottom:40px}
		.margin-bottom-xl{margin-bottom:50px}
		.margin-left-xs{margin-left:10px}
		.margin-left-sm{margin-left:20px}
		.margin-left{margin-left:30px}
		.margin-left-lg{margin-left:40px}
		.margin-left-xl{margin-left:50px}
		.margin-lr-xs{margin-left:10px;margin-right:10px}
		.margin-lr-sm{margin-left:20px;margin-right:20px}
		.margin-lr{margin-left:30px;margin-right:30px}
		.margin-lr-lg{margin-left:40px;margin-right:40px}
		.margin-lr-xl{margin-left:50px;margin-right:50px}
		.margin-tb-xs{margin-top:10px;margin-bottom:10px}
		.margin-tb-sm{margin-top:20px;margin-bottom:20px}
		.margin-tb{margin-top:30px;margin-bottom:30px}
		.margin-tb-lg{margin-top:40px;margin-bottom:40px}
		.margin-tb-xl{margin-top:50px;margin-bottom:50px}
		.margin-top-0{margin-top:0px}
		.margin-bottom-0{margin-bottom:0px}
		
		.padding-0{padding:0}
		.padding-left-0{padding-left:0}
		.padding-xs{padding:10px}
		.padding-sm{padding:20px}
		.padding{padding:30px}
		.padding-lg{padding:40px}
		.padding-xl{padding:50px}
		.padding-top-xs{padding-top:10px}
		.padding-top-sm{padding-top:20px}
		.padding-top{padding-top:30px}
		.padding-top-lg{padding-top:40px}
		.padding-top-xl{padding-top:50px}
		.padding-right-xs{padding-right:10px}
		.padding-right-sm{padding-right:20px}
		.padding-right{padding-right:30px}
		.padding-right-lg{padding-right:40px}
		.padding-right-xl{padding-right:50px}
		.padding-bottom-xs{padding-bottom:10px}
		.padding-bottom-sm{padding-bottom:20px}
		.padding-bottom{padding-bottom:30px}
		.padding-bottom-lg{padding-bottom:40px}
		.padding-bottom-xl{padding-bottom:50px}
		.padding-left-xs{padding-left:10px}
		.padding-left-sm{padding-left:20px}
		.padding-left{padding-left:30px}
		.padding-left-lg{padding-left:40px}
		.padding-left-xl{padding-left:50px}
		.padding-lr-xs{padding-left:10px;padding-right:10px}
		.padding-lr-sm{padding-left:20px;padding-right:20px}
		.padding-lr{padding-left:30px;padding-right:30px}
		.padding-lr-lg{padding-left:40px;padding-right:40px}
		.padding-lr-xl{padding-left:50px;padding-right:50px}
		.padding-tb-xs{padding-top:10px;padding-bottom:10px}
		.padding-tb-sm{padding-top:20px;padding-bottom:20px}
		.padding-tb{padding-top:30px;padding-bottom:30px}
		.padding-tb-lg{padding-top:40px;padding-bottom:40px}
		.padding-tb-xl{padding-top:50px;padding-bottom:50px}
		
		
		
		.fc-1{color: #ff5134;}
		.fc-2{color: #B3B3B3;}
		.fc-3{color: #0D0D0D;}
		.fc-4{color: #C92E2E;}
		.fc-5{color: #ffffff;}
		.fc-6{color: #696A80;}
		.fc-7{color: #61607D;}
		.fc-c{color: #cccccc;}
		.fc-80{color: #808080;}
		.fc-money{color: #E60303;}
		.decoloe{color: #a4a7c4;}
		.dpk{color:#D67575;}
		.dc{color: #333333;}
		.dc1{color: #848499;}
		.dc2{color: #147DBC;}
		.blue{color: #3c9cff;}
		.fc-9{color: rgb(220, 76, 77);}
		.fc-10{color:#282F49;} 
		.fc-11{color: #F7D88F;}
		.fc-12{color:#b21112;}
		.fc-13{color:#B20C0D;}
		.bai{
			color: #fff;
		}
		.gray{
			color: #808080;
		}
		.fred{
			 color:#B20C0D;
		}
		.hei{
			color: #000;
		}
		.red{
			color:#FE4F4F
		}
		.green{
			color:#01A928
		}
		.view-color{
			color:#ff6600;
		}
		.zongcolor{
			color:#ebbea3;
		}
		.gray-color{
			color:#828282
		}
		.black{
			color: #000000;
		}
		.bg-h{
			background: linear-gradient(to right, #332f34,#5a595c);
			padding: 8px;
			border-radius: 8px;
		}
		.bg-red{
			background: red;
			font-size: 24px;
			transform: scale(0.7);
			line-height: 30px;
			text-align: center;
			border-radius: 50%;
		}
		.bg-hui{
			background-color:#f7f7f7;
			border-radius: 0 0 4px 4px;
		}
		.bg-bai{
			background-color:#fff;
			border-radius: 10px;
		}
		.bg-huib{
			background-color:#fff;
			border-radius: 20px;
		}
		.bg-lan{
			background-color:#3481eb;
		}
		.bg-huic{
			background-color:#fff;
			border-radius: 10px;
		}
		.bg-f6{
			background-color: #f6f6f6;
		}
		
		.br{
			border-radius: 10px;
		}
		.br20{
			border-radius: 20px;
		}
		
		.bor-area{
			background-color: white;
			border: 1px solid #B20C0D;
		} 
    .point{
      cursor: pointer;
    }
	.ishover{
	
	}
	.noshadow{
		box-shadow:none!important;
	}
	.ishover:hover .v-icon{
		color: #ff5134!important;
		font-weight: bold;
	}
	.ishover:hover{
		color: #ff5134!important;
		font-weight: bold;

	}
	.v-dialogs {
  font-family: 'Arial', sans-serif; /* 修改字体 */
  color: #333; /* 修改文字颜色 */
}

.v-dialogs .dialog-content {
  padding: 20px; /* 修改内边距 */
}

.v-dialogs .dialog-button {
  background-color: #007bff; /* 修改按钮背景颜色 */
  color: #fff; /* 修改按钮文字颜色 */
}

	
</style>
