import request from '@/axios/index';
// 注册
export function register(params) {
    return request.post('api/authors/register', params);
}
// 登录
export function login(params) {
    return request.post('api/authors/login', params);
}
// 重置
export function reset(params) {
    return request.post('api/authors/reset_pwd', params);
}
export function send(params) {
    return request.post('api/send_sms', params);
}
// // 登录注册
// export const login = data => request({ url: 'api/authors/login', method: 'post', data })
// // 获取验证码
// export const send = data => request({ url: 'api/sms/send', method: 'post', data })