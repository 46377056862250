import request from '@/axios/index';
// 用户
export function user(params) {
    return request.get('api/authors/info', params);
}
// 更新用户信息
export function updatainfo(params) {
    return request.post('api/authors/update', params);
}
// 分类列表1
export function cate(params) {
    return request.get(`api/authors/category?pd=${params.id}`,);
}
// 子类列表1
export function cateinfo(params) {
    return request.get(`api/authors/sub_category?cid=${params.id}`,);
}
// 创建书籍
export function createbook(params) {
    return request.post('api/authors/create_book', params);
}
// 书籍列表1
export function booklist(params) {
    return request.get(`api/authors/book_list`, params);
}
// 创建章节
export function createchapter(params) {
    return request.post('api/authors/create_chapter', params);
}
// 章节列表1
export function chapterlist(params) {
    return request.get('api/authors/chapter_list', params);
}
// 章节详情
export function chapterdetail(params) {
    return request.get(`api/authors/chapter_detail?book_id=${params.id}&chapter_id=${params.chapter_id}`);

}
// 打赏统计1
export function gift(params) {
    return request.get(`api/authors/gift/statistics?book_id=${params.id}`);
}
// 年月打赏统计1
export function ymgift(params) {
    return request.get(`api/authors/gift/year_or_month?book_id=${params.id}&date=${params.date}&type=${params.type}`);
}
// 收藏统计1
export function collect(params) {
    return request.get(`api/authors/collect/statistics?book_id=${params.id}`);
}
// 年月统计1
export function ymcollect(params) {
    return request.get(`api/authors/collect/year_or_month?book_id=${params.id}&date=${params.date}&type=${params.type}`);
}
// 推荐票统计1
export function recommend(params) {
    return request.get(`api/authors/recommend/statistics?book_id=${params.id}`);

}
// 年月统计1
export function ymrecommend(params) {
    return request.get(`api/authors/recommend/year_or_month?book_id=${params.id}&date=${params.date}&type=${params.type}`);
}
// 作者日历1
export function calendar(params) {
    return request.get(`api/authors/calendar?book_id=${params.id}&date=${params.date}`);
}
// 通知1
export function notice(params) {
    return request.get('api/home/news', params);
}
// 通知1
export function noticedetail(params) {
    return request.get(`api/home/newsDetail/${params.id}`);
}
// 上传
export function upload(params) {
    return request.fileupload('api/upload', params);
}
// 发布书
export function publishbook(params) {
    return request.post('api/authors/publish_book', params);
}

// 发布章节
export function publishchapter(params) {
    return request.post('api/authors/publish_chapter', params);
}


