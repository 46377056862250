import Vue from 'vue'
import VueRouter from 'vue-router'
import {getStorage,setStorage} from "@/common/utils.js"

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {
    path:'/',
    name:'home',
    redirect:'/home'
  },
  {
    path: '/home',
    component: () => import('../views/MainView.vue'),
    children:[
      {
        path:'/',
        name: 'main',
        component:() => import('../views/main/Index.vue')
      },
      // 创建作品
      {
        path:'/workmanager',
        name: 'workmanager',
        component:() => import('../views/main/workmanager.vue')
      },
      // 管理作品列表
      {
        path:'/workList',
        name: 'workList',
        component:() => import('../views/main/workList.vue')
      },
      {
        path:'/dydata',
        name: 'dydata',
        component:() => import('../views/main/dydata.vue')
      },
      {
        path:'/dsdata',
        name: 'dsdata',
        component:() => import('../views/main/dsdata.vue')
      },
      {
        path:'/scdata',
        name: 'scdata',
        component:() => import('../views/main/scdata.vue')
      },
      {
        path:'/tjdata',
        name: 'tjdata',
        component:() => import('../views/main/tjdata.vue')
      },
      {
        path:'/srgl',
        name: 'srgl',
        component:() => import('../views/main/srgl.vue')
      },
      {
        path:'/notice',
        name: 'notice',
        component:() => import('../views/main/notice.vue')
      },
      {
        path:'/me',
        name: 'me',
        component:() => import('../views/main/me.vue')
      },
    ]
  },
  {
    path: '/login',
    component: () => import('../views/LoginView.vue'),
    children:[
      {
        path:'/',
        name: 'login',
        component:() => import('../views/login/Index.vue')
      },
      {
        path:'/register',
        name:'register',
        component:() => import('../views/login/Register.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//路由守卫
router.beforeEach((to, from, next) => {
  if (to.path =='/home' && !getStorage("token")) {
    next("/login");
  } else {
    next();
  }
});

export default router
